exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-about-us-jsx": () => import("./../../../src/pages/about_us/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-about-us-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact_us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-outra-pagina-jsx": () => import("./../../../src/pages/outra-pagina.jsx" /* webpackChunkName: "component---src-pages-outra-pagina-jsx" */),
  "component---src-pages-solutions-app-modernization-jsx": () => import("./../../../src/pages/solutions/app-modernization.jsx" /* webpackChunkName: "component---src-pages-solutions-app-modernization-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

